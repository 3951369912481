import type { Category } from './types';

const data: Category[] = [
  {
    title: 'My certifications',
    projects: [
      {
        title: 'HTB CPTS',
        date: '25/02/2022',
        description: 'Hack The Box - Certified Penetration Testing Specialist',
        logo: 'mdi-sword-cross',
        image: 'certs/cpts.png',
        url: 'https://www.credly.com/badges/a9127655-c3b7-47f7-ad18-3e2fc765c1cf/public_url',
      },
    ],
  },
  {
    title: 'My side projects',
    projects: [
      {
        title: 'SQLi Semi-automatic exploitation',
        date: '25/07/2022',
        description: 'Tool to exploit SQL injections with a full control on the sent requests',
        logo: 'mdi-language-python',
        image: 'projects/my-sqli.png',
        url: 'https://github.com/labbealexandre/my-sqli',
      },
      {
        title: 'MITM Proxy search tool',
        date: '15/11/2022',
        description: 'Proxy to search in the requests and the responses bodies for a specific substring',
        logo: 'mdi-nodejs',
        image: 'projects/my-proxy.png',
        url: 'https://github.com/labbealexandre/my-mitm-proxy',
      },
      {
        title: 'Libdns plugin for Exoscale',
        date: '15/11/2022',
        description: 'Go Implementation of a libdns plugin to connect to Exoscale API and manage DNS records',
        logo: 'mdi-language-go',
        image: 'projects/exoscale-libdns.png',
        url: 'https://github.com/labbealexandre/exoscale',
      },
    ],
  },
  {
    title: 'My CVEs',
    projects: [
      {
        title: 'CVE-2024-22276',
        date: '27/06/2024',
        description: 'VMware Cloud Director Object Storage Extension addresses an Insertion of Sensitive Information vulnerability',
        logo: 'mdi-shield-off',
        image: 'projects/vmware.jpg',
        url: 'https://support.broadcom.com/web/ecx/support-content-notification/-/external/content/SecurityAdvisories/0/24372',
      },
      {
        title: 'CVE-2024-22277',
        date: '04/07/2024',
        description: 'VMware Cloud Director Availability addresses an HTML injection vulnerability',
        logo: 'mdi-shield-off',
        image: 'projects/vmware.jpg',
        url: 'https://support.broadcom.com/web/ecx/support-content-notification/-/external/content/SecurityAdvisories/0/24557',
      },
      {
        title: 'CVE-2024-20469',
        date: '04/09/2024',
        description: 'OS Command Injection in Cisco Identity Service Engine (ISE)',
        logo: 'mdi-shield-off',
        image: 'projects/cisco.png',
        url: 'https://www.offensity.com/en/blog/os-command-injection-in-cisco-identity-service-engine-ise-cve-2024-20469/',
      },
    ],
  },
];

export default data;
