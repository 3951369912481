<!-- eslint-disable max-len -->
<template>
  <v-container>
    <span v-for="category in categories" :key="category.title">
      <v-row class="mt-6">
        <v-col>
          <h1 class="primary--text text-md-h2 text-h3 mb-6 header-title">
            {{ category.title }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="project in category.projects" :key="project.title" :sm="12" :md="6" :lg="4">
          <BoardCard :title="project.title" :date="project.date" :description="project.description" :logo="project.logo"
            :image="project.image" :url="project.url" />
        </v-col>
      </v-row>
    </span>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import BoardCard from './BoardCard.vue';

import categories from '../data';

@Component({
  components: {
    BoardCard,
  },
})
export default class Board extends Vue {
  categories = categories
}

</script>
